<template>
	<div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="">
							<div class="row">
								<div class="col-md-3">
									<qr-code :text="itemID" class="qrcode-item"></qr-code>
								</div>
								<div class="col-md-9">
									<div class="row">

										<!-- name -->
										<div class="col-md-8">
											<div class="form-group">
												<label class="form-control-label" for="input-name">Nome</label>
												<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" maxlenght="60" class="form-control" placeholder="Nome" v-model="name" required="false" >
												<div class="invalid-feedback d-block" v-if="errors.name">
													<p>{{ errors.name[0] }}</p>
												</div>
											</div>
										</div>
										<!-- end name -->

										<!-- status -->
										<div class="col-md-4">
											<div class="form-group">
												<label class="form-control-label" for="input-status_id">Status</label>
												<v-select :options="optionsStatus" v-bind:class="{'is-invalid': errors.status_id}" :reduce="label => label.value" v-model="status_id" required></v-select>
												<div class="invalid-feedback d-block" v-if="errors.status_id">
													<p>{{ errors.status_id[0] }}</p>
												</div>
											</div>
										</div>
										<!-- end status -->

										<!-- description -->
										<div class="col-md-12">
											<div class="form-group">
												<label class="form-control-label" for="input-description">Descrição</label>
												<input type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" maxlength="60" class="form-control" placeholder="Descrição" v-model="description" required="true" >
												<div class="invalid-feedback d-block" v-if="errors.description">
													<p>{{ errors.description[0] }}</p>
												</div>
											</div>
										</div>
										<!-- end description -->

									</div>
								</div>
							</div>
						

							<div class="row">

								<!-- complement_description -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-complement_description">Complemento da Descrição</label>
										<textarea type="text" id="input-complement_description" v-bind:class="{'is-invalid': errors.complement_description}" class="form-control" placeholder="Complemento da Descrição" v-model="complement_description" rows="4" maxlength="250" required="false"></textarea>
										<div class="invalid-feedback d-block" v-if="errors.complement_description">
											<p>{{ errors.complement_description[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end complement_description -->

								<!-- manufacturing_number -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-manufacturing_number">Número de Fabricação</label>
										<input type="text" id="input-manufacturing_number" v-bind:class="{'is-invalid': errors.manufacturing_number}"   class="form-control" placeholder="Número de Fabricação" v-model="manufacturing_number" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.manufacturing_number">
											<p>{{ errors.manufacturing_number[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end manufacturing_number -->

								<!-- seal_number -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-seal_number">Número do Selo</label>
										<input type="text" id="input-seal_number" v-bind:class="{'is-invalid': errors.seal_number}"   class="form-control" placeholder="Número do Selo" v-model="seal_number" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.seal_number">
											<p>{{ errors.seal_number[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end seal_number -->

								<!-- seal_inmetro -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-seal_inmetro">Selo Inmetro</label>
										<input type="text" id="input-seal_inmetro" v-bind:class="{'is-invalid': errors.seal_inmetro}"   class="form-control" placeholder="Selo Inmetro" v-model="seal_inmetro" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.seal_inmetro">
											<p>{{ errors.seal_inmetro[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end seal_inmetro -->

								<!-- asset_registration_number -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-asset_registration_number">Número Cadastro Patrimônio</label>
										<input type="text" id="input-asset_registration_number" v-bind:class="{'is-invalid': errors.asset_registration_number}"   class="form-control" placeholder="Número Cadastro Patrimônio" v-model="asset_registration_number" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.asset_registration_number">
											<p>{{ errors.asset_registration_number[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end asset_registration_number -->

								<!-- rated_load -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-rated_load">Carga Nominal</label>
										<input type="text" id="input-rated_load" v-bind:class="{'is-invalid': errors.rated_load}"   class="form-control" placeholder="Carga Nominal" v-model="rated_load" required="true" >
										<div class="invalid-feedback d-block" v-if="errors.rated_load">
											<p>{{ errors.rated_load[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end rated_load -->

								<!-- inch -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-inch">Polegada</label>
										<input type="text" id="input-inch" v-bind:class="{'is-invalid': errors.inch}"   class="form-control" placeholder="Polegada" v-model="inch" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.inch">
											<p>{{ errors.inch[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end inch -->

								<!-- year_manufacture -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-year_manufacture">Ano de Fabricação</label>
										<input type="number" id="input-year_manufacture" v-bind:class="{'is-invalid': errors.year_manufacture}"  maxlength="4" class="form-control" placeholder="Ano de Fabricação" v-model="year_manufacture" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.year_manufacture">
											<p>{{ errors.year_manufacture[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end year_manufacture -->

								<!-- next_retest -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-next_retest">Próximo Reteste</label>
										<input type="date" id="input-next_retest" v-bind:class="{'is-invalid': errors.next_retest}"   class="form-control" placeholder="Próximo Reteste" v-model="next_retest" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.next_retest">
											<p>{{ errors.next_retest[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end next_retest -->

								<!-- next_recharge -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-next_recharge">Próxima Recarga</label>
										<input type="date" id="input-next_recharge" v-bind:class="{'is-invalid': errors.next_recharge}"   class="form-control" placeholder="Próxima Recarga" v-model="next_recharge" required="false" >
										<div class="invalid-feedback d-block" v-if="errors.next_recharge">
											<p>{{ errors.next_recharge[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end next_recharge -->

								<!-- price -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-price">Preço</label>
										<money v-model="price" v-bind:class="{'is-invalid': errors.price}" v-bind="money" class="form-control"></money>
										<!-- <input type="text" id="input-price" v-bind:class="{'is-invalid': errors.price}"   class="form-control" placeholder="Preço" v-model="price" required="false" > -->
										<div class="invalid-feedback d-block" v-if="errors.price">
											<p>{{ errors.price[0] }}</p>
										</div>
									</div>
								</div><!-- col-md-3 -->
								<!-- end price -->

								<!-- category_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-category_id">Categoria</label>
										<v-select :options="categories" label="name" v-bind:class="{'is-invalid': errors.category_id}" :reduce="name => name.id" v-model="category_id" @input="validateSubCategory()" required></v-select>
										<div class="invalid-feedback d-block" v-if="errors.category_id">
											<p>{{ errors.category_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end category_id -->

								<!-- subcategory_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-subcategory_id">Subcategoria</label>
										<v-select :options="subcategories" label="name" v-bind:class="{'is-invalid': errors.subcategory_id}" :reduce="name => name.id" v-model="subcategory_id" required></v-select>
										<div class="invalid-feedback d-block" v-if="errors.subcategory_id">
											<p>{{ errors.subcategory_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end subcategory_id -->

								<!-- load_type_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-load_type_id">Tipo de Carga</label>
										<v-select :options="loadtypes" label="name" v-bind:class="{'is-invalid': errors.load_type_id}" :reduce="name => name.id" v-model="load_type_id" required></v-select>
										<div class="invalid-feedback d-block" v-if="errors.load_type_id">
											<p>{{ errors.load_type_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end load_type_id -->

								<!-- manufacturer_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-manufacturer_id">Fabricante</label>
										<v-select :options="manufacturers" label="name" v-bind:class="{'is-invalid': errors.manufacturer_id}" :reduce="name => name.id" v-model="manufacturer_id" required></v-select>
										<div class="invalid-feedback d-block" v-if="errors.manufacturer_id">
											<p>{{ errors.manufacturer_id[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end manufacturer_id -->

								<!-- comments -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-comments">Comentários</label>
										<textarea type="text" id="input-comments" v-bind:class="{'is-invalid': errors.comments}" class="form-control" placeholder="Comentários" v-model="comments" rows="8" required="false"></textarea>
										<div class="invalid-feedback d-block" v-if="errors.comments">
											<p>{{ errors.comments[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end comments -->

							</div>
						</div>
						<!-- end form -->

					</div><!-- card body -->
				</div><!-- card -->
			</div><!-- col-12 -->

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div><!-- row -->
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ["items"],
		data() {
			return {
				itemID: String(this.getData('id')),
				description: this.getData("description"),
				complement_description: this.getData("complement_description"),
				name: JSON.parse(this.getData("name")),
				manufacturing_number: this.getData("manufacturing_number"),
				seal_number: this.getData("seal_number"),
				seal_inmetro: this.getData("seal_inmetro"),
				asset_registration_number: this.getData("asset_registration_number"),
				rated_load: this.getData("rated_load"),
				inch: this.getData("inch"),
				year_manufacture: this.getData("year_manufacture"),
				next_retest: this.getData("next_retest"),
				next_recharge: this.getData("next_recharge"),
				price: this.getData("price"),
				comments: this.getData("comments"),
				category_id: this.getData("category_id"),
				categories: [],
				subcategory_id: this.getData("subcategory_id"),
				subcategories: [],
				load_type_id: this.getData("load_type_id"),
				loadtypes: [],
				manufacturer_id: this.getData("manufacturer_id"),
				manufacturers: [],
				optionsStatus: [{
					label: 'Ativo',
					value: 1
				},{
					label: 'Inativo',
					value: 0
				},{
					label: 'Pausado',
					value: 2
				}],
				money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        },
				status_id: this.getData("status"),
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadCategories();
			this.loadSubCategories();
			this.loadLoadTypes();
			this.loadManufacturers();
		},
		methods: {
			getData(key) {
				return JSON.parse(this.items)[key];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"description" : _this.description,
					"complement_description" : _this.complement_description,
					"name" : _this.name,
					"manufacturing_number" : _this.manufacturing_number,
					"seal_number" : _this.seal_number,
					"seal_inmetro" : _this.seal_inmetro,
					"asset_registration_number" : _this.asset_registration_number,
					"rated_load" : _this.rated_load,
					"inch" : _this.inch,
					"year_manufacture" : _this.year_manufacture,
					"next_retest" : _this.next_retest,
					"next_recharge" : _this.next_recharge,
					"price" : _this.price,
					"comments" : _this.comments,
					"category_id" : _this.category_id,
					"subcategory_id" : _this.subcategory_id,
					"load_type_id" : _this.load_type_id,
					"manufacturer_id" : _this.manufacturer_id,
					"_method": "PATCH"
				};
				axios.post("/admin/items/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			loadCategories() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/categories").then((res) => {
					_this.categories = _this.categories.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSubCategories() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/subcategories").then((res) => {
					_this.subcategories = _this.subcategories.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadLoadTypes() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/loadtypes").then((res) => {
					_this.loadtypes = _this.loadtypes.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadManufacturers() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/manufacturers").then((res) => {
					_this.manufacturers = _this.manufacturers.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
