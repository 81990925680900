require('./bootstrap');

window.Vue = require('vue');

//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

//CHART
// import labels from 'chartjs-plugin-labels';
// Vue.use( labels );

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MASK
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

//MOMENT
Vue.use(require('vue-moment'));

//QR CODE
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

//VUE-GOOD-TABLE
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

//VUE SELECT
import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

//V-money
import money from 'v-money';
Vue.use(money, {precision: 4});


//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
Vue.component('adminwidgets-component', adminWidgetsComponent);

import adminBarChartComponent from './components/admin/dashboard/charts/bar';
import adminDoughnutChartComponent from './components/admin/dashboard/charts/doughnut';
import adminPieChartComponent from './components/admin/dashboard/charts/pie';
Vue.component('admin-bar-component', adminBarChartComponent);
Vue.component('admin-donghnut-component', adminDoughnutChartComponent);
Vue.component('admin-piechart-component', adminPieChartComponent);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
import LogGenerateActivityComponent from './components/admin/logs/generateactivity';
Vue.component('logaccess-component',LogAccessComponent);
Vue.component('logactivity-component',LogActivityComponent);
Vue.component('logerror-component',LogErrorComponent);
Vue.component('loggenerateactivity-component',LogGenerateActivityComponent);

//permissions
import permissionAddComponent from './components/admin/permissions/add';
import permissionEditComponent from './components/admin/permissions/edit';
import permissionListComponent from './components/admin/permissions/list';
Vue.component('permissions-add-component', permissionAddComponent);
Vue.component('permissions-edit-component', permissionEditComponent);
Vue.component('permissions-list-component', permissionListComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//iteminventories
import iteminventoriesListComponent from './components/admin/items/inventories/list';
Vue.component('iteminventories-list-component', iteminventoriesListComponent);

//subsectors
import subsectorsListComponent from './components/admin/sublocal/subsectors/list';
Vue.component('subsectors-list-component', subsectorsListComponent);

//sectors
import sectorsListComponent from './components/admin/sublocal/sectors/list';
Vue.component('sectors-list-component', sectorsListComponent);

//activities
import activitiesListComponent from './components/admin/activities/list';
Vue.component('activities-list-component', activitiesListComponent);
import activitiesAddComponent from './components/admin/activities/add';
Vue.component('activities-add-component', activitiesAddComponent);
import activitiesEditComponent from './components/admin/activities/edit';
Vue.component('activities-edit-component', activitiesEditComponent);

import activitieDataComponent from './components/admin/activities/view/data';
Vue.component('activities-data-component', activitieDataComponent);
import activitieDashboardComponent from './components/admin/activities/view/dashboard';
Vue.component('activities-dashboard-component', activitieDashboardComponent);

//occurrences
import occurrencesListComponent from './components/admin/occurrences/list';
Vue.component('occurrences-list-component', occurrencesListComponent);
import occurrencesAddComponent from './components/admin/occurrences/add';
Vue.component('occurrences-add-component', occurrencesAddComponent);
import occurrencesEditComponent from './components/admin/occurrences/edit';
Vue.component('occurrences-edit-component', occurrencesEditComponent);
import occurrencesViewComponent from './components/admin/occurrences/view';
Vue.component('occurrences-view-component', occurrencesViewComponent);

//itemattachments
import itemattachmentsListComponent from './components/admin/items/attachments/list';
Vue.component('itemattachments-list-component', itemattachmentsListComponent);

//items
import itemsListComponent from './components/admin/items/list';
Vue.component('items-list-component', itemsListComponent);
import itemsAddComponent from './components/admin/items/add';
Vue.component('items-add-component', itemsAddComponent);
import itemsEditComponent from './components/admin/items/edit';
Vue.component('items-edit-component', itemsEditComponent);

//inventory
import inventoryListComponent from './components/admin/items/inventory';
Vue.component('inventory-list-component', inventoryListComponent);

//templatemessages
import templatemessagesListComponent from './components/admin/templates/messages/list';
Vue.component('templatemessages-list-component', templatemessagesListComponent);

//templatenotifications
import templatenotificationsListComponent from './components/admin/templates/notifications/list';
Vue.component('templatenotifications-list-component', templatenotificationsListComponent);

//templateepis
import templateepisListComponent from './components/admin/templates/epis/list';
Vue.component('templateepis-list-component', templateepisListComponent);

//templatechecklists
import templatechecklistsListComponent from './components/admin/templates/checklists/list';
Vue.component('templatechecklists-list-component', templatechecklistsListComponent);

//checklists
import checklistsListComponent from './components/admin/checklists/list';
Vue.component('checklists-list-component', checklistsListComponent);

//templates
import templatesListComponent from './components/admin/templates/list';
Vue.component('templates-list-component', templatesListComponent);
import templatesAddComponent from './components/admin/templates/add';
Vue.component('templates-add-component', templatesAddComponent);
import templatesEditComponent from './components/admin/templates/edit';
Vue.component('templates-edit-component', templatesEditComponent);

//configurationnotifications
import configurationnotificationsListComponent from './components/admin/configurationnotifications/list';
Vue.component('configurationnotifications-list-component', configurationnotificationsListComponent);
import configurationnotificationsAddComponent from './components/admin/configurationnotifications/add';
Vue.component('configurationnotifications-add-component', configurationnotificationsAddComponent);
import configurationnotificationsEditComponent from './components/admin/configurationnotifications/edit';
Vue.component('configurationnotifications-edit-component', configurationnotificationsEditComponent);

//workplaceresponsibles
import workplaceresponsiblesListComponent from './components/admin/workplaces/responsibles/list';
Vue.component('workplaceresponsibles-list-component', workplaceresponsiblesListComponent);

//workplace sublocal
import workplaceSublocalListComponent from './components/admin/workplaces/sublocal/list';
Vue.component('workplace-sublocal-list-component', workplaceSublocalListComponent);

//sublocal
import sublocalListComponent from './components/admin/sublocal/list';
Vue.component('sublocal-list-component', sublocalListComponent);
import sublocalAddComponent from './components/admin/sublocal/add';
Vue.component('sublocal-add-component', sublocalAddComponent);
import sublocalEditComponent from './components/admin/sublocal/edit';
Vue.component('sublocal-edit-component', sublocalEditComponent);

//workplaces
import workplacesListComponent from './components/admin/workplaces/list';
Vue.component('workplaces-list-component', workplacesListComponent);
import workplacesAddComponent from './components/admin/workplaces/add';
Vue.component('workplaces-add-component', workplacesAddComponent);
import workplacesEditComponent from './components/admin/workplaces/edit';
Vue.component('workplaces-edit-component', workplacesEditComponent);

//colaborators
import colaboratorsListComponent from './components/admin/colaborators/list';
Vue.component('colaborators-list-component', colaboratorsListComponent);
import colaboratorsAddComponent from './components/admin/colaborators/add';
Vue.component('colaborators-add-component', colaboratorsAddComponent);
import colaboratorsEditComponent from './components/admin/colaborators/edit';
Vue.component('colaborators-edit-component', colaboratorsEditComponent);
import colaboratorsCoursesComponent from './components/admin/colaborators/courses';
Vue.component('colaborators-courses-component', colaboratorsCoursesComponent);
import colaboratorsCertificationsComponent from './components/admin/colaborators/certifications/list';
Vue.component('colaborators-certifications-component', colaboratorsCertificationsComponent);

//companies
import companiesListComponent from './components/admin/companies/list';
Vue.component('companies-list-component', companiesListComponent);
import companiesAddComponent from './components/admin/companies/add';
Vue.component('companies-add-component', companiesAddComponent);
import companiesEditComponent from './components/admin/companies/edit';
Vue.component('companies-edit-component', companiesEditComponent);

//contracts
import contractsListComponent from './components/admin/contracts/list';
Vue.component('contracts-list-component', contractsListComponent);
import contractsAddComponent from './components/admin/contracts/add';
Vue.component('contracts-add-component', contractsAddComponent);
import contractsEditComponent from './components/admin/contracts/edit';
Vue.component('contracts-edit-component', contractsEditComponent);

//manufacturers
import manufacturersListComponent from './components/admin/manufacturers/list';
Vue.component('manufacturers-list-component', manufacturersListComponent);

//loadtypes
import loadtypesListComponent from './components/admin/loadtypes/list';
Vue.component('loadtypes-list-component', loadtypesListComponent);

//consumptiontypes
import consumptiontypesListComponent from './components/admin/consumptiontypes/list';
Vue.component('consumptiontypes-list-component', consumptiontypesListComponent);

//epitypes
import epitypesListComponent from './components/admin/epitypes/list';
Vue.component('epitypes-list-component', epitypesListComponent);

//messages
import messagesListComponent from './components/admin/messages/list';
Vue.component('messages-list-component', messagesListComponent);

//incidentstatus
import incidentstatusListComponent from './components/admin/incidentstatus/list';
Vue.component('incidentstatus-list-component', incidentstatusListComponent);

//activitystatus
import activitystatusListComponent from './components/admin/activitystatus/list';
Vue.component('activitystatus-list-component', activitystatusListComponent);

//allowdelayfrequency
import allowdelayfrequencyListComponent from './components/admin/allowdelayfrequency/list';
Vue.component('allowdelayfrequency-list-component', allowdelayfrequencyListComponent);

//allowdelays
import allowdelaysListComponent from './components/admin/allowdelays/list';
Vue.component('allowdelays-list-component', allowdelaysListComponent);

//frequencies
import frequenciesListComponent from './components/admin/frequencies/list';
Vue.component('frequencies-list-component', frequenciesListComponent);

//typeproblems
import typeproblemsListComponent from './components/admin/typeproblems/list';
Vue.component('typeproblems-list-component', typeproblemsListComponent);

//subcategories
import subcategoriesListComponent from './components/admin/subcategories/list';
Vue.component('subcategories-list-component', subcategoriesListComponent);

//categories
import categoriesListComponent from './components/admin/categories/list';
Vue.component('categories-list-component', categoriesListComponent);

//colaboratorgroups
import colaboratorgroupsListComponent from './components/admin/companies/colaboratorgroups/list';
Vue.component('colaboratorgroups-list-component', colaboratorgroupsListComponent);

//companycolaborators
import companyColaboratorComponent from './components/admin/companies/colaborators/list';
Vue.component('company-colaborators-component', companyColaboratorComponent);

//company contracts
import companyContractsComponent from './components/admin/companies/contracts/list';
Vue.component('company-contracts-component', companyContractsComponent);

//company notifications
import companyNotificationsComponent from './components/admin/companynotifications/list';
Vue.component('company-notifications-component', companyNotificationsComponent);

//companyworkplaces
import companyWorkplaceComponent from './components/admin/companies/workplaces/list';
Vue.component('company-workplaces-component', companyWorkplaceComponent);

//companyworkplacecolaborators
import companyWorkplaceColaboratorComponent from './components/admin/companies/workplacecolaborators/list';
Vue.component('company-workplacecolaborators-component', companyWorkplaceColaboratorComponent);

//professions
import professionsListComponent from './components/admin/professions/list';
Vue.component('professions-list-component', professionsListComponent);

//companytypes
import companytypesListComponent from './components/admin/companytypes/list';
Vue.component('companytypes-list-component', companytypesListComponent);

//reports
import reportOccurrenceComponent from './components/admin/reports/occurrences';
import reportActivityComponent from './components/admin/reports/activities';
import reportCertificationsComponent from './components/admin/reports/certifications';
Vue.component('report-occurrences-component', reportOccurrenceComponent);
Vue.component('report-activities-component', reportActivityComponent);
Vue.component('report-certifications-component', reportCertificationsComponent);

//export
import gridFullExportComponent from './components/admin/gridFullExport';
Vue.component('gridfullexport-component', gridFullExportComponent);

//certifications
import certificationsListComponent from './components/admin/certifications/list';
Vue.component('certifications-list-component', certificationsListComponent);


//RENDER
const app = new Vue({
	el: '#app',
	methods: {
		MakeUrl(path) {
			return BaseUrl(path);
		}
	}
});
