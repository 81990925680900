<template>
  <div class="tab-pane fade" id="tab-certifications" role="tabpanel" aria-labelledby="certifications-tab">
    <div>
      <div class="row section-top">
        <div class="col-md-12 pad0">
          <p class="section-lead">Lista de todas Certificações cadastradas para o Colaborador</p>
          <a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalNewCertification"><i class="fas fa-plus"></i>&nbsp;Novo</a>
        </div>
      </div>

      <div class="row">
        <div class="col">

          <!-- table -->
          <div class="card p-4">
            <vue-good-table v-if="colaboratorCertificationsItems.length"
              :columns="tableColumns"
              :rows="colaboratorCertificationsItems"
              :pagination-options="tablePagination"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar..',
              }"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'">
                  <div>
                    <button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
                    <button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div v-else class="text-center p-3 text-muted">
              <h5>Nenhuma certificação cadastrada!</h5>
            </div>
          </div>

          <div class="text-center p-4 text-muted" v-if="loading">
            <h5>Carregando...</h5>
            <p>Aguarde até que os dados sejam carregados...</p>
          </div>

        </div>

        <!-- modal -->
        <div class="modal fade" id="modalNewCertification" tabindex="-1" aria-labelledby="modalNewCertificationLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalNewCertificationLabel">{{modalCertificationTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form v-on:submit.prevent="saveData">

                  <div class="row">

                    <div class="col-md-6">
                      <div class="row">

                        <!-- certification -->
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-control-label" for="input-certification_id">Certificação</label>
                            <v-select :options="certifications" label="name" v-bind:class="{'is-invalid': errors.certification_id}" :reduce="name => name.id" v-model="modalCertification.certification_id"></v-select>
                            <div class="invalid-feedback d-block" v-if="errors.certification_id">
                              <p>{{ errors.certification_id[0] }}</p>
                            </div>
                          </div>
                        </div>

                        <!-- institution -->
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-control-label" for="input-institution">Nome da Instituição</label>
                            <input type="text" id="input-institution" v-bind:class="{'is-invalid': errors.institution}" class="form-control" placeholder="Nome da Instituição" v-model="modalCertification.institution">
                            <div class="invalid-feedback d-block" v-if="errors.institution">
                              <p>{{ errors.institution[0] }}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="row">
                        
                        <!-- number -->
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-control-label" for="input-number">Nº do Certificado</label>
                            <input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}" class="form-control" placeholder="Nº do Certificado" v-model="modalCertification.number">
                            <div class="invalid-feedback d-block" v-if="errors.number">
                              <p>{{ errors.number[0] }}</p>
                            </div>
                          </div>
                        </div>

                        <!-- date -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-conclusion_date">Data de Conclusão</label>
                            <input type="date" id="input-name" v-bind:class="{'is-invalid': errors.conclusion_date}" class="form-control" placeholder="Data" v-model="modalCertification.conclusion_date">
                            <div class="invalid-feedback d-block" v-if="errors.conclusion_date">
                              <p>{{ errors.conclusion_date[0] }}</p>
                            </div>
                          </div>
                        </div>

                        <!-- validity -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label" for="input-validity">Validade</label>
                            <input type="date" id="input-name" v-bind:class="{'is-invalid': errors.validity}" class="form-control" placeholder="Validade" v-model="modalCertification.validity" >
                            <div class="invalid-feedback d-block" v-if="errors.validity">
                              <p>{{ errors.validity[0] }}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <!-- observations -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-control-label" for="input-observations">Observações</label>
                        <textarea type="text" id="input-observations" maxlength="500" v-bind:class="{'is-invalid': errors.observations}" class="form-control" placeholder="Descrição" v-model="modalCertification.observations" rows="8"></textarea>
                        <div class="invalid-feedback d-block" v-if="errors.observations">
                          <p>{{ errors.observations[0] }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <h6 class="heading-small text-muted mb-4">Arquivos</h6>
                    </div>

                    <div class="col-md-12">
                      <label class="form-control-label" for="input-certificationFile">Clique para selecionar o arquivo (tamanho máximo 5mb)</label>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="certificationFile" name="certificationFile" accept="*" ref="certificationFile" lang="pt" v-on:change="handleFileUpload()">
                        <label class="custom-file-label" for="logotipo">{{fileName}}</label>
                      </div>

                    </div>
                    
                    <div class="col-md-12" v-if="!isEditColaboratorCertification">
                      <div class="list-certification-files" v-for="item, index in modalCertification.files" :key="index">
                        <div class="item-file">
                          {{item.name}}
                          <span class="image-delete" @click="removeFile(index)">
                            <i class="fas fa-trash-alt"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 pad0" v-else>
                      <div class="col-md-12 list-certification-files" v-for="item, index in modalCertification.files" :key="index">
                        <div class="item-file">
                          {{item.file}}
                          <span class="image-delete" @click="removeFile(index)">
                            <i class="fas fa-trash-alt"></i>
                          </span>

                          <span class="text-secondary" style="margin-right: 10px;" @click="viewFile(item)">
                            <i class="fas fa-eye"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                
                  </div>

                  <div class="text-right mt-4">
                    <button type="submit" class="btn btn-success">SALVAR</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- end modal -->

      <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
      </div>
    </div>
  </div>
</template>

<script>
	import ApiService from '../../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['colaborators'],
		data() {
			return {
				modalCertificationTitle: "Nova Certificação",
				fileName: 'Clique para selecionar o arquivo',
        modalCertification: {
          files: []
        },
				isEditColaboratorCertification: false,
				colaboratorCertificationID: "",
				colaboratorCertificationsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
        certifications: [],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Certificação',
						field: 'info.name',
					},{
						label: 'Nº',
						field: 'number',
					},{
						label: 'Instituição',
						field: 'institution',
					},{
						label: 'Data de Conclusão',
						field: 'conclusion_date',
            type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Validade',
						field: 'validity',
            type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Observações',
						field: 'observations',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.loadItems();
      this.loadCertifications();
		},
		methods: {
      handleFileUpload(){

        //validate size
				if (this.$refs.certificationFile.files[0].size > 5000000){

          //define error
          var error = {
            response: {
              status: 500,
              data: {
                message: "Tamanho máximo de arquivo permitido 5mb"
              }
            }
          }

          this._api_validateError(error);

        } else {

          this.modalCertification.files.push({
            file: this.$refs.certificationFile.files[0],
            name: this.$refs.certificationFile.files[0].name
          });
        
        }
            
			},
      getData(key) {
				return JSON.parse(this.colaborators)[key];
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/colaboratorcertifications/"+_this.getData('id')+"/search").then((res) => {
					_this.colaboratorCertificationsItems = _this.colaboratorCertificationsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditColaboratorCertification == true){

          let formData = new FormData();
				  formData.append("certification_id", _this.modalCertification.certification_id);
          formData.append("number", _this.modalCertification.number);
          formData.append("institution", _this.modalCertification.institution);
          formData.append("conclusion_date", _this.modalCertification.conclusion_date);
          formData.append("validity", _this.modalCertification.validity);
          formData.append("observations", _this.modalCertification.observations);
          formData.append("colaborator_id", _this.getData('id'));
          formData.append("_method", "PATCH");

					axios.post("/admin/colaboratorcertifications/"+_this.colaboratorCertificationID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

            var countFiles = 1;
            var certificationID = res.data.data.id;

            this.modalCertification.files.map((rowPicture) => {

              let formData = new FormData();
              formData.append("file", rowPicture.file);
              formData.append("certification_id", certificationID);

              axios.post("/admin/colaboratorcertificationfiles", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

                if(countFiles == this.modalCertification.files.length){

                  _this.loading = false;
                  _this.resetForm();

                  $('#modalNewCertification').modal('hide');

                  //call function in ApiService to validate success
                  _this._api_validateSuccess(res,false);

                } else {

                  countFiles++;

                }

              }).catch((err) => {

                _this.loading = false;

                if (err.response.data.errors){
                  //set errors
                  _this.errors = err.response.data.errors;
                }

                //call function in ApiService to validate error
                _this._api_validateError(err);

              });

            });

					}).catch((err) => {

						_this.loading = false;

						//set errors
          	_this.errors = err.response.data.errors;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

          if(this.modalCertification.files.length > 0){

            let formData = new FormData();
            formData.append("certification_id", _this.modalCertification.certification_id);
            formData.append("number", _this.modalCertification.number);
            formData.append("institution", _this.modalCertification.institution);
            formData.append("conclusion_date", _this.modalCertification.conclusion_date);
            formData.append("validity", _this.modalCertification.validity);
            formData.append("observations", _this.modalCertification.observations);
            formData.append("colaborator_id", _this.getData('id'));

            axios.post("/admin/colaboratorcertifications", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

              var countFiles = 1;
              var certificationID = res.data.data.id;
              var certificationResult = res.data.data;

              this.modalCertification.files.map((rowPicture) => {

                let formData = new FormData();
                formData.append("file", rowPicture.file);
                formData.append("certification_id", certificationID);

                axios.post("/admin/colaboratorcertificationfiles", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

                  if(countFiles == this.modalCertification.files.length){

                    _this.loading = false;
                    _this.resetForm();

                    _this.colaboratorCertificationsItems.push(certificationResult);
                    $('#modalNewCertification').modal('hide');

                    //call function in ApiService to validate success
                    _this._api_validateSuccess(res,false);

                  } else {

                    countFiles++;

                  }

                }).catch((err) => {

                  _this.loading = false;

                  if (err.response.data.errors){
                    //set errors
                    _this.errors = err.response.data.errors;
                  }

                  //call function in ApiService to validate error
                  _this._api_validateError(err);

                });

              });

            }).catch((err) => {

              _this.loading = false;

              //set errors
              _this.errors = err.response.data.errors;

              //call function in ApiService to validate error
              _this._api_validateError(err);

            });
          } else {
            _this.loading = false;
          }
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Certificação do Colaborador",
					text: "Tem certeza que deseja excluir esse Certificação?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/colaboratorcertifications/"+ID).then((res) => {

							//_this.modalCertification.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.modalCertification = item;
				this.isEditColaboratorCertification = true;
				this.colaboratorCertificationID = item.id;
				this.modalCertificationTitle = "Editar Certificação";

				$("#modalNewCertification").modal('show');

			},
      loadCertifications() {
				let _this = this;
				axios.get("/admin/certifications").then((res) => {
					_this.certifications = _this.certifications.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.modalCertification = {
					certification_id: '',
          institution: '',
          number: '',
					conclusion_date: '',
          validity: '',
          observations: '',
          files: []
				};
				this.errors = [];

				this.isEditColaboratorCertification = false;
				this.colaboratorCertificationID = "";
				this.modalCertificationTitle = "Nova Certificação";

			},
      removeFile(index){
				this.modalCertification.files.splice(index,1);
			},
      viewFile(file){
        window.open('/storage/colaborators/certifications/'+file.file,'_blank');
      }
		}
	}
</script>
