<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- title -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-title">Título</label>
									<input type="text" id="input-title" v-bind:class="{'is-invalid': errors.title}"  class="form-control" placeholder="Título" v-model="title" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.title">
										<p>{{ errors.title[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end title -->

							<!-- activity_status_id -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-activity_status_id">Status</label>
									<v-select :options="activitystatus" label="name" v-bind:class="{'is-invalid': errors.activity_status_id}" :reduce="name => name.id" v-model="activity_status_id" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.activity_status_id">
										<p>{{ errors.activity_status_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end activity_status_id -->

							<!-- description -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-description">Descrição</label>
									<textarea type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}" class="form-control" placeholder="Descrição" v-model="description" rows="8" required="false"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.description">
										<p>{{ errors.description[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end description -->

							<!-- details -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-details">Detalhes</label>
									<textarea type="text" id="input-details" v-bind:class="{'is-invalid': errors.details}" class="form-control" placeholder="Detalhes" v-model="details" rows="8" required="true"></textarea>
									<div class="invalid-feedback d-block" v-if="errors.details">
										<p>{{ errors.details[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end details -->

							<!-- expected_date -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-expected_date">Data Prevista</label>
									<input type="date" id="input-expected_date" v-bind:class="{'is-invalid': errors.expected_date}"   class="form-control" placeholder="Data prevista" v-model="expected_date" required="false">
									<div class="invalid-feedback d-block" v-if="errors.expected_date">
										<p>{{ errors.expected_date[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end expected_date -->

							<!-- expected_time -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-expected_time">Hora Prevista</label>
									<input type="time" id="input-expected_time" v-bind:class="{'is-invalid': errors.expected_time}"   class="form-control" placeholder="Hora Prevista" v-model="expected_time" required="false" >
									<div class="invalid-feedback d-block" v-if="errors.expected_time">
										<p>{{ errors.expected_time[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end expected_time -->

							<!-- time -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-time">Tempo</label>
									<input type="tel" id="input-time" v-bind:class="{'is-invalid': errors.time}"   class="form-control" placeholder="Tempo" v-model="time" required="false"  :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.time">
										<p>{{ errors.time[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end time -->

							<!-- duration -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-duration">Duração</label>
									<input type="time" id="input-duration" v-bind:class="{'is-invalid': errors.duration}"   class="form-control" placeholder="Duração" v-model="duration" required="false" :disabled="true">
									<div class="invalid-feedback d-block" v-if="errors.duration">
										<p>{{ errors.duration[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end duration -->

							<!-- template_id -->
							<div class="col-md-8">
								<div class="form-group">
									<label class="form-control-label" for="input-template_id">Template</label>
									<v-select :options="templates" label="name" v-bind:class="{'is-invalid': errors.template_id}" :reduce="name => name.id" v-model="template_id" @input="validateDelay()" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.template_id">
										<p>{{ errors.template_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end template_id -->

							<!-- allow delay -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-allow_delay">Permitir Atraso?</label>
									<v-select :options="allowDelays" v-bind:class="{'is-invalid': errors.allow_delay}" v-model="allow_delay" :disabled="allowDelayDisabled" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.allow_delay">
										<p>{{ errors.allow_delay[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end allow delay -->

							<!-- companY -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Empresa</label>
									<v-select :options="companies" label="corporate_name" v-bind:class="{'is-invalid': errors.company_id}" :reduce="corporate_name => corporate_name.id" v-model="company_id" required @input="validateRelationships()"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.company_id">
										<p>{{ errors.company_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end company -->

							<!-- contracts -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-contract_id">Contrato</label>
									<v-select :options="contracts" label="corporate_name" v-bind:class="{'is-invalid': errors.contract_id}" :reduce="corporate_name => corporate_name.id" v-model="contract_id" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.contract_id">
										<p>{{ errors.contract_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end contracts -->

							<!-- work_place_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
									<v-select :options="workplaces" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.work_place_id" v-model="work_place_id" required @input="validateWorkplace()"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.work_place_id">
										<p>{{ errors.work_place_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end work_place_id -->

							<!-- sublocal -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-sublocal_id">Sublocal de Trabalho</label>
									<v-select :options="sublocal" label="corporate_name" v-bind:class="{'is-invalid': errors.work_place_id}" :reduce="corporate_name => corporate_name.id" v-model="sublocal_id" required @input="validateSublocal()"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.sublocal_id">
										<p>{{ errors.sublocal_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end sublocal -->

							<!-- colaborator_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-colaborator_id">Colaborador</label>
									<v-select :options="colaborators" multiple label="name" v-bind:class="{'is-invalid': errors.colaborator_id}" :reduce="name => name.colaborator_id" v-model="colaborator_id" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.colaborator_id">
										<p>{{ errors.colaborator_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end colaborator_id -->							

							<!-- colaborator_group_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-colaborator_group_id">Grupo de Colaboradores</label>
									<v-select :options="colaboratorgroups" multiple label="name" v-bind:class="{'is-invalid': errors.colaborator_group_id}" :reduce="name => name.id" v-model="colaborator_group_id" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.colaborator_group_id">
										<p>{{ errors.colaborator_group_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end colaborator_group_id -->

							<!-- certification -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-certification_id">Certificações Necessárias</label>
									<v-select :options="certifications" multiple label="name" v-bind:class="{'is-invalid': errors.certification_id}" :reduce="name => name.id" v-model="certification_id"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.certification_id">
										<p>{{ errors.certification_id[0] }}</p>
									</div>
								</div>
							</div>

							<!-- type -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-type">Tipo de Atividade</label>
									<v-select :options="typeOptions" v-bind:class="{'is-invalid': errors.type}" v-model="type" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.type">
										<p>{{ errors.type[0] }}</p>
									</div>
								</div>
							</div>

							<!-- item_id -->
							<div class="col-md-8" v-if="type == 'item'">
								<div class="form-group">
									<label class="form-control-label" for="input-item_id">Item</label>
									<v-select :options="items" label="name" v-bind:class="{'is-invalid': errors.item_id}" :reduce="name => name.id" v-model="item_id" required @input="selectItem()"></v-select>
									<div class="invalid-feedback d-block" v-if="errors.item_id">
										<p>{{ errors.item_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end item_id -->

							<!-- sectors -->
							<div class="col-md-4" v-if="type == 'local'">
								<div class="form-group">
									<label class="form-control-label" for="input-sector_id">Setor</label>
									<v-select :options="sectors" label="name" v-bind:class="{'is-invalid': errors.sector_id}" :reduce="name => name.id" v-model="sector_id" @input="validateSector()" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.sector_id">
										<p>{{ errors.sector_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end sectors -->

							<!-- subsectors -->
							<div class="col-md-4" v-if="type == 'local'">
								<div class="form-group">
									<label class="form-control-label" for="input-subsector_id">Subsetor</label>
									<v-select :options="subsectors" label="name" v-bind:class="{'is-invalid': errors.subsector_id}" :reduce="name => name.id" v-model="subsector_id" required></v-select>
									<div class="invalid-feedback d-block" v-if="errors.subsector_id">
										<p>{{ errors.subsector_id[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end subsectors -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				activitystatus: [],
				activity_status_id: '',
				allowDelays: [],
				allow_delay: '',
				allowDelayDisabled: false,
				title: "",
				certifications: [],
				certification_id: '',
				companies: [],
				company_id: '',
				contracts: [],
				contract_id: '',
				description: "",
				details: "",
				expected_date: "",
				expected_time: "",
				time: "",
				duration: "",
				item_id: 0,
				sectors: [],
				sector_id: "",
				subsectors: [],
				subsector_id: null,
				sublocal: [],
				sublocal_id: '',
				items: [],
				tempItems: [],
				work_place_id: "",
				workplaces: [],
				template_id: "",
				templates: [],
				colaborator_id: "",
				colaborators: [],
				colaborator_group_id: "",
				colaboratorgroups: [],
				tempColaborators: [],
				tempColaboratorsGroups: [],
				tempContracts: [],
				type: "item",
				typeOptions: ['item','local'],
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadItems();
			this.loadCompanies();
			this.loadTemplates();
			this.loadActivityStatus();
			this.loadCertifications();
			//this.loadContracts();
			//this.loadSublocal();
		},
		methods: {
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";

				if(_this.validateDate()){

					_this.loading = true;
					let dataInfo = {
						"title" : _this.title,
						"description" : _this.description,
						"details" : _this.details,
						"expected_date" : _this.expected_date,
						"expected_time" : _this.expected_time,
						"time" : _this.time,
						"duration" : _this.duration,
						"item_id" : _this.item_id,
						"work_place_id" : _this.work_place_id,
						"template_id" : _this.template_id,
						"colaborator_id" : _this.colaborator_id,
						"colaborator_group_id" : _this.colaborator_group_id,
						"activity_status_id": _this.activity_status_id,
						"allow_delay": _this.allow_delay,
						"company_id": _this.company_id,
						"sublocal_id": _this.sublocal_id,
						"contract_id": _this.contract_id,
						"type": _this.type,
						"sector_id": _this.sector_id,
						"subsector_id": _this.subsector_id,
						"certification_id": _this.certification_id,
						"last": ""
					};
					axios.post("/admin/activities", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,true);

					}).catch((err) => {

						_this.loading = false;

						if (err.response.data.errors){
							//set errors
							_this.errors = err.response.data.errors;
						}

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					this.$swal({
						title: 'Atenção',
						text: 'Data Prevista não pode ser menor que a Data Atual',
						icon: 'warning'
					});

				}
			},
			resetForm() {
				this.title = '';
				this.description = '';
				this.details = '';
				this.expected_date = '';
				this.expected_time = '';
				this.time = '';
				this.duration = '';
				this.item_id = '';
				this.work_place_id = '';
				this.template_id = '';
				this.colaborator_id = '';
				this.colaborator_group_id = '';
			},
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/items").then((res) => {
					_this.tempItems = _this.tempItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadCertifications() {
				let _this = this;
				axios.get("/admin/certifications").then((res) => {
					_this.certifications = _this.certifications.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCompanies() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/contracts").then((res) => {
					_this.tempContracts = _this.tempContracts.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSublocal() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/sublocal").then((res) => {
					_this.sublocal = _this.sublocal.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadActivityStatus() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/activitystatus").then((res) => {
					_this.activitystatus = _this.activitystatus.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadWorkPlaces() {
				let _this = this;
				_this.loading = true;
				_this.workplaces = [];
				axios.get("/admin/companyworkplaces/"+_this.company_id+"/search").then((res) => {
					//_this.workplaces = _this.workplaces.concat(res.data);
					res.data.map((row) => {
						row.corporate_name = row.work_places.corporate_name;
						_this.workplaces.push(row);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadTemplates() {
				let _this = this;
				_this.templates = [];
				_this.loading = true;
				axios.get("/admin/templates").then((res) => {
					_this.templates = _this.templates.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadColaborators() {
				let _this = this;
				_this.colaborators = [];
				_this.tempColaborators = [];
				_this.loading = true;
				axios.get("/admin/companycolaborators/"+_this.company_id+"/search").then((res) => {
					res.data.map((row) => {
						row.name = row.colaborators.users.profile.name;
						_this.tempColaborators.push(row);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadColaboratorGroups() {
				let _this = this;
				_this.colaboratorgroups = [];
				_this.tempColaboratorsGroups = [];
				_this.loading = true;
				axios.get("/admin/colaboratorgroups/"+_this.company_id+"/search").then((res) => {
					_this.tempColaboratorsGroups = _this.tempColaboratorsGroups.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			selectItem(){
				this.tempItems.map((rowTempItem) => {
					if(rowTempItem.id == this.item_id){
						this.sector_id = rowTempItem.inventory.sector_id;
						this.subsector_id = rowTempItem.inventory.subsector_id;
					}
				});
			},
			validateDelay(){
				let _this = this;
				_this.allowDelays = [];
				
				//read all templates to get info of the selected
				_this.templates.map((itemTemplate) => {
					if(itemTemplate.id == _this.template_id){

						switch (itemTemplate.frequencies.type) {
							case 'day':
								if (itemTemplate.frequencies.time == 1){
									_this.allowDelayDisabled = true;
								} else {
									for(var i = 1; i < itemTemplate.frequencies.time; i++){
										_this.allowDelays.push(i);
									}
								}								
								break;

							case 'month':
								var monthAllowDellay = 31 * itemTemplate.frequencies.time;
								for(var i = 1; i <= monthAllowDellay; i++){
									_this.allowDelays.push(i);
								}
								break;

							case 'year':
								for(var i = 1; i <= 12; i++){
									_this.allowDelays.push(i);
								}
								break;
						
							default:
								_this.allowDelayDisabled = true;
								break;
						}

					}
				});
			},
			validateRelationships(){
				this.loadColaboratorGroups();
				this.loadColaborators();
				this.loadWorkPlaces();
				this.validateContracts();
			},
			validateContracts(){
				let _this = this;
				_this.contracts = [];
				_this.loading = true;
				axios.get("/admin/companycontracts/"+_this.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.contracts.push(rowContract.contracts);
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateDate(){
				var today = moment();
				var diff = today.diff(this.expected_date,'days');
				
				if(diff > 0){
					return false;
				} else {
					return true;
				}
				
			},
			validateWorkplace(){
				let _this = this;
				_this.loading = true;
				_this.sublocal = [];
				axios.get("/admin/workplacesublocal/"+_this.work_place_id+"/search").then((res) => {
					res.data.map((rowSublocal) => {
						_this.sublocal.push({
							corporate_name: rowSublocal.sublocal.corporate_name,
							id: rowSublocal.sublocal_id
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateSublocal(){
				let _this = this;
				_this.loading = true;

				axios.get("/admin/companyworkplacecolaborators/"+_this.company_id+"/search").then((res) => {
					
					res.data.map((itemWorkplaceColaborator) => {

						if(itemWorkplaceColaborator.work_place_id == _this.work_place_id){

							//read all colaborators
							_this.tempColaborators.map((itemColaborator) => {
								if(itemColaborator.colaborator_id == itemWorkplaceColaborator.colaborator_id){
									if(!_this.colaborators.some(data => data.id === itemColaborator.id)){
										_this.colaborators.push(itemColaborator);   
									}
								}
							});
							
							_this.tempItems.map((rowItem) =>{
								if(rowItem.inventory !== null && rowItem.inventory !== undefined){
									if(rowItem.inventory.sublocal_id == _this.sublocal_id){
										var exists = false;
										_this.items.map((rowNewItem) => {
											if (rowNewItem.id == rowItem.id){
												exists = true;
											}
										});
										if(exists == false){
											_this.items.push({
												name: rowItem.name,
												id: rowItem.id
											});
										}
									}
								}
							});

							//read all colaborator groups
							_this.tempColaboratorsGroups.map((itemColaboratorGroup) => {
								if(itemColaboratorGroup.id == itemWorkplaceColaborator.colaborator_group_id){
									if(!_this.colaboratorgroups.some(data => data.id === itemColaboratorGroup.id)){
										_this.colaboratorgroups.push(itemColaboratorGroup);   
									}
								}
							});

						}
					});

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});

				//load sectors
				_this.sectors = [];
				_this.loading = true;
				axios.get("/admin/sectors/"+_this.sublocal_id+"/search").then((res) => {
					_this.sectors = _this.sectors.concat(res.data);
					_this.validateSector();
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			validateSector(){
				let _this = this;
				_this.subsectors = [];
				_this.loading = true;
				axios.get("/admin/subsectors/"+_this.sublocal_id+"/search").then((res) => {
					res.data.map((rowSubsector) => {
						if(rowSubsector.sector_id == _this.sector_id){
							_this.subsectors.push(rowSubsector);
						}
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
