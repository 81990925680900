<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Itens cadastrados</p>
				<a href="/admin/items/create" class="btn btn-outline-success float-right"><i class="fas fa-plus"></i>&nbsp;Novo</a>
				<a class="btn btn-outline-warning float-right" data-toggle="collapse" href="#panelFilter" role="button" aria-expanded="false" aria-controls="panelFilter"><i class="fas fa-filter"></i>&nbsp;Filtrar</a>
				<div class="dropdown float-right">
					<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i class="fas fa-download"></i>
						Exportar
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#" @click="exportData('pdf')">PDF</a>
						<a class="dropdown-item" href="#" @click="exportData('csv')">Excel</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#" @click="$refs.gridfullexport.showModal('items','items')">Completo</a>
					</div>
				</div>
				<a class="btn btn-outline-secondary float-right" data-toggle="modal" data-target="#modalImport"><i class="fas fa-upload"></i>&nbsp;Importar</a>
				<a class="btn btn-outline-secondary float-right" @click="generateCode()"><i class="fas fa-qrcode"></i>&nbsp;Gerar</a>
			</div>
		</div>

		<!-- filter -->
		<div class="row">
			<div class="col-md-12">
				<div class="collapse" id="panelFilter">
					<div class="card card-body">
						<div class="row">

							<!-- company id -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Empresa</label>
									<v-select :options="filterCompanies" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterItems.company_id" @input="validateCompany()"></v-select>
								</div>
							</div>

							<!-- contract -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Contrato</label>
									<v-select :options="filterContracts" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterItems.contract_id"></v-select>
								</div>
							</div>

							<!-- work_place_id -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-work_place_id">Local de Trabalho</label>
									<v-select :options="filterWorkplaces" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterItems.work_place_id" @input="validateWorkplace()"></v-select>
								</div>
							</div>

							<!-- sublocal -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-sublocal">Sublocal</label>
									<v-select :options="filterSublocal" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filterItems.sublocal_id" @input="validateSublocal('filter')"></v-select>
								</div>
							</div>

							<!-- sector_id -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-sector_id">Setor</label>
									<v-select :options="filterSectors" label="name" :reduce="name => name.id" v-model="filterItems.sector_id" @input="validateSector()"></v-select>
								</div>
							</div>
							
							<!-- subsector_id -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-subsector_id">Subsetor</label>
									<v-select :options="filterSubsectors" :reduce="name => name.id" label="name" v-model="filterItems.subsector_id"></v-select>
								</div>
							</div>

							<!-- name -->
							<div class="col-md-12 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name"   class="form-control" placeholder="Nome" v-model="filterItems.name" required="false" >
								</div>
							</div>

							<!-- manufacturing_number -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-manufacturing_number">Número de Fabricação</label>
									<input type="text" id="input-manufacturing_number"   class="form-control" placeholder="Número de Fabricação" v-model="filterItems.manufacturing_number" required="false" >
								</div>
							</div>
		
							<!-- seal_number -->
							<div class="col-md-6 mt-2">
								<div class="form-group">
									<label class="form-control-label" for="input-seal_number">Número do Selo</label>
									<input type="text" id="input-seal_number"   class="form-control" placeholder="Número do Selo" v-model="filterItems.seal_number" required="false" >
								</div>
							</div>
							
							<div class="col-md-12 mt-2 text-right">
								<a class="btn btn-outline-secondary" style="margin-right: 20px;" @click="clearFilter()">LIMPAR</a>
								<button class="btn btn-success" @click="filter()">FILTRAR</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- grid -->
		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="itemsItems.length"
						:columns="tableColumns"
						:rows="itemsItems"
						:pagination-options="{
								enabled: true,
								perPage: 500
						}"
						:select-options="{ enabled: true }"
						@on-selected-rows-change="selectionChanged"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="pagination-bottom">
							<div style="display: flex; justify-content: space-between; align-items: center;">
									<div style="display: flex; align-items: center; justify-content: center; margin-top: 8px;">
											<label tyle="font-size: 12px; margin-top: 8px">
													{{ tablePagination.rowsPerPageLabel }}:
											</label>
											<select
													v-model="currentPerPage"
													@change="event => changePerPage(event.target.value)"
													style="margin-left: 8px; font-size: 12px; font-weight: bold; border: none;"
											>
													<option
															v-for="option in perPageOptions"
															:key="option"
															:value="option"
															>{{ option }}</option
													>
											</select>
									</div>

									<div style="display: flex; align-items: center; margin-top: 8px;">
											<span style="font-size: 12px;">
												{{ from }} - {{ to }} de
												{{ total }}</span
											>

											<div style="display: flex; gap: 8px; margin-left: 16px;">
													<div
															@click="prevPage"
															:style="{
																	cursor: canGoPrev
																			? 'pointer'
																			: 'not-allowed',
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: canGoPrev
																			? 'inherit'
																			: '#ccc'
															}"
															:disabled="!canGoPrev"
													>
															◀
															{{ tablePagination.prevLabel }}
													</div>
													<div
															@click="nextPage"
															:style="{
																	cursor: canGoNext
																			? 'pointer'
																			: 'not-allowed',
																	fontSize: '12px',
																	fontWeight: 'bold',
																	color: canGoNext
																			? 'inherit'
																			: '#ccc'
															}"
															:disabled="!canGoNext"
													>
															{{ tablePagination.nextLabel }}
															▶
													</div>
											</div>
									</div>
							</div>
						</template>

						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.index)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum itens cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
			
			<!-- modal -->
			<div class="modal fade" id="modalQRCode" tabindex="-1" aria-labelledby="modalQRCodeLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalQRCodeLabel">Gerar</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="generateCode">

								<div class="row">

									<!-- type -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-type">Selecione o Tipo</label>
											<v-select :options="optionsType" v-bind:class="{'is-invalid': errors.type}" :reduce="label => label.value" v-model="generate.type" required></v-select>
											<div class="invalid-feedback d-block" v-if="errors.type">
												<p>{{ errors.type[0] }}</p>
											</div>
										</div>
									</div>
									<!-- end type -->

									<!-- items -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-items">Items</label>
											<v-select :options="itemsItems" label="name" v-bind:class="{'is-invalid': errors.items}" :reduce="name => name.id" v-model="generate.items" multiple required></v-select>
											<div class="invalid-feedback d-block" v-if="errors.items">
												<p>{{ errors.items[0] }}</p>
											</div>
										</div>
									</div>
									<!-- end items -->
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">GERAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

			<!-- modal import -->
			<div class="modal fade" id="modalImport" tabindex="-1" aria-labelledby="modalImportLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalImportLabel">Importar Itens</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="importItems">

								<div class="row">

									<div class="col-md-12">
										<a href="/storage/items.xlsx" target="_blank" class="btn btn-outline-secondary btn-outline-default">Baixar Modelo de Formato do Arquivo</a>
									</div>

									<!-- type -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-icon">Selecione o arquivo</label>
											<div class="custom-file">
												<input type="file" class="custom-file-input" v-bind:class="{'is-invalid': errors.icon}" id="icon" name="icon" accept="*" ref="icon" lang="pt" v-on:change="handleFileTemplateUpload()">
												<label class="custom-file-label" for="icon">{{fileImportName}}</label>
											</div>
											<div class="invalid-feedback d-block" v-if="errors.icon">
												<p>{{ errors.icon[0] }}</p>
											</div>
										</div>
									</div>
									<!-- end type -->
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">IMPORTAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal import -->

			<gridfullexport-component ref="gridfullexport"></gridfullexport-component>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	import ExportGrid from '../../exportgrid.js';
	export default {
		mixins: [ApiService, ExportGrid],
		props: ['permissions'],
		data() {
			return {
				categories: [],
        filterCompanies: [],
        filterContracts: [],
        filterWorkplaces: [],
        filterSublocal: [],
        filterSectors: [],
        filterSubsectors: [],
				tempSubsectors: [],
				gridSelected: [],
				subcategories: [],
				loadtypes: [],
				manufacturers: [],
				itemsItems: [],
				fullPage: true,
				fileImportName: '',
				importFile: '',
				generate: {
					type: '',
					items: []
				},
				errors: [],
				loading: false,
				optionsType: [{
					label: 'QR Code',
					value: 1
				},{
					label: 'Etiqueta',
					value: 2
				}],
				perPageOptions: [10, 50, 100, 500],
				currentPage: 1,
				currentPerPage: 10,
				total: null,
				from: 0,
				to: 0,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'ID',
						field: 'id',
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Empresa',
						field: 'inventory.company_workplace.company.corporate_name',
					},{
						label: 'Local de Trabalho',
						field: 'inventory.work_places.corporate_name',
					},{
						label: 'Sublocal de Trabalho',
						field: 'inventory.sublocal.corporate_name',
					},{
						label: 'Setor',
						field: 'inventory.sectors.name',
					},{
						label: 'Subsetor',
						field: 'inventory.subsectors.name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				filterItems: {},
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadCompanies();
		},
		watch: {
        currentPage: "loadItems",
        currentPerPage: "loadItems"
    },
		computed: {
        canGoNext() {
            return this.currentPage * this.currentPerPage < this.total;
        },
        canGoPrev() {
            return this.currentPage > 1;
        }
    },
		methods: {
			changePerPage(value) {
				this.currentPerPage = value;
			},
			nextPage() {
				if (this.canGoNext) {
					this.currentPage++;
				}
			},
			prevPage() {
				if (this.canGoPrev) {
					this.currentPage--;
				}
			},
			loadItems() {
				this.filter();
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.filterCompanies = _this.filterCompanies.concat(res.data);
				}).catch((err) => {
				});
			},
			loadContracts() {
				let _this = this;
				_this.loading = true;
				_this.filterContracts = [];
				axios.get("/admin/companycontracts/"+_this.filterItems.company_id+"/search").then((res) => {
					res.data.map((rowContract) => {
						_this.filterContracts.push({
							id: rowContract.contract_id,
							corporate_name: rowContract.contracts.corporate_name
						});
					});
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSectors() {
				let _this = this;
				_this.loading = true;

        _this.filterSectors = [];

        axios.get("/admin/sectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
          _this.filterSectors = _this.filterSectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
			loadSubsectors() {
				let _this = this;
				_this.loading = true;

        _this.filterSubsectors = [];
				_this.tempSubsectors = [];
      
        axios.get("/admin/subsectors/"+_this.filterItems.sublocal_id+"/search").then((res) => {
          _this.tempSubsectors = _this.tempSubsectors.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			},
			exportData(type){
				var tempForm = $('#vgt-table');
				if(type == 'pdf'){
					this._exportPDF('Itens', [0,4], tempForm, ['','ID','Nome','Data e Hora']);
				} else {
					this._exportExcel('Itens', [0,4], tempForm, ['','ID','Nome','Data e Hora']);
				}
    	},
			handleFileTemplateUpload(){
				this.importFile = this.$refs.icon.files[0];
				this.fileImportName = this.$refs.icon.files[0].name;
			},
			generateCode(){

				if(this.gridSelected.length == 0){

					this.$swal({
						title: 'Erro',
						text: "Selecione os itens que deseja Gerar os QRCodes",
						icon: 'error'
					});

				} else {

					let _this = this;
					_this.loading = true;

					let dataInfo = {
						"items": _this.gridSelected
					};
					axios.post("/admin/items/generate/image", dataInfo).then((res) => {

						_this.loading = false;

						//window.open('/storage/items/exported/qrcode.zip','_blank');
						window.open('/storage/items/exported/'+res.data.file,'_blank');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				}

			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Item",
					text: "Tem certeza que deseja excluir esse Item?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/items/"+ID).then((res) => {

							//_this.items.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			clearFilter(){

				this.filterItems = {
					company_id: null,
					contract_id: null,
					work_place_id: null,
					sublocal_id: null,
					sector_id: null,
					subsector_id: null,
					name: null,
					manufacturer_name: null,
					seal_number: null
				};

				this.loadItems();
			},
			filter(){
				let _this = this;
				_this.loading = true;

				_this.itemsItems = [];

				let dataFilter = {
					"company_id" : _this.filterItems.company_id,
					"contract_id" : _this.filterItems.contract_id,
					"work_place_id" : _this.filterItems.work_place_id,
					"sublocal_id" : _this.filterItems.sublocal_id,
					"sector_id" : _this.filterItems.sector_id,
					"subsector_id" : _this.filterItems.subsector_id,
					"name" : _this.filterItems.name,
					"manufacturing_number" : _this.filterItems.manufacturing_number,
					"seal_number" : _this.filterItems.seal_number
				};
				axios.post("/admin/items/filter", dataFilter, {params: {page: _this.currentPage, perPage: _this.currentPerPage}}).then((res) => {

					const { data, total, from, to } = res.data.data;
					_this.itemsItems = [...data];
					_this.total = total;
					_this.from = from;
					_this.to = to;

					_this.loading = false;

				}).catch((err) => {

					_this.loading = false;

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			editData(item){
				location.href="/admin/items/"+item.id+"/edit";
			},
			resetForm(){

				this.item = {
					description: '',
					complement_description: '',
					name: '',
					manufacturing_number: '',
					seal_number: '',
					seal_inmetro: '',
					asset_registration_number: '',
					rated_load: '',
					inch: '',
					year_manufacture: '',
					next_retest: '',
					next_recharge: '',
					price: '',
					comments: '',
					category_id: '',
					subcategory_id: '',
					load_type_id: '',
					manufacturer_id: '',
				};

			},
			selectionChanged(params) {
				// params.selectedRows - all rows that are selected (this page)

				// if (this.gridSelected.length == 0){
				// 	$("#panelMove").slideToggle();
				// } else {
				// 	if(params.selectedRows.length == 0){
				// 		$("#panelMove").slideToggle();
				// 	}
				// }
				this.gridSelected = params.selectedRows;
			},
			importItems(){

				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				
				let formData = new FormData();
				formData.append("file", _this.importFile);

				axios.post("/admin/items/import", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
          	_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);
					
				});

			},
			validateCompany(){
				let _this = this;
				_this.loading = true;

        _this.filterWorkplaces = [];
        axios.get("/admin/companyworkplaces/"+_this.filterItems.company_id+'/search').then((res) => {
          res.data.map((rowReturn) => {
            _this.filterWorkplaces = _this.filterWorkplaces.concat(rowReturn.work_places);
          });
          _this.loadContracts();
        }).catch((err) => {
        });
        this.loading = false;
			},
			validateSector(){
				let _this = this;
				_this.filterSubsectors = [];

				_this.tempSubsectors.map((rowSubsector) => {
					if(rowSubsector.sector_id == _this.filterItems.sector_id){
						_this.filterSubsectors.push(rowSubsector);
					}
				});
			},
			validateSublocal(source){
				let _this = this;

				_this.loadSectors(source);
				_this.loadSubsectors(source);

			},
      validateWorkplace(){
				let _this = this;
        _this.loading = true;

        _this.filterSublocal = [];
        axios.get("/admin/workplacesublocal/"+_this.filterItems.work_place_id+"/search").then((res) => {
          res.data.map((rowSublocal) => {
            _this.filterSublocal.push({
              corporate_name: rowSublocal.sublocal.corporate_name,
              id: rowSublocal.sublocal_id
            });
          });
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });

			}
		}
	}
</script>
